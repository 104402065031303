<template>
    <div class="exContentFive">
        <div class="ecFBox">
            <!-- 标题 -->
            <div class="ecFTitle wow animate__animated animate__slideInDown">
                <p class="ecFBig">客情管理</p>
                <p class="ecFSmall">客户离开后，如何分析客情，达到精准营销？</p>
                <p class="ecFSmall">
                    根据Neolane调查，67%的直销企业使用大数据来帮助他们实现更高的转化率。大数据已在获得转化力的营销目标上
                    展示了巨大的潜力。企业利用大数据分析，能够以更好的方式了解客户，并帮助企业更轻松地将数据转化为订单。
                </p>
            </div>
            <!-- 图片 -->
            <div class="ecFImgs">
                <img src="https://iv.okvu.cn/vugw/img/pic-4.png" alt="" class="ecFImg wow animate__animated animate__slideInUp" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ExContentFive",
    data() {
        return {};
    },
    mounted() {
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: "ease-in-sine",
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: "wow",
            animateClass: "animate__animated",
            offset: 0,
            mobile: true,
            live: true,
        });
    },
};
</script>

<style lang="less" scoped>
.exContentFive {
    width: 100%;
    height: 600px;
    margin-top: 50px;
    //   background-color: rgb(102, 141, 135);
    .ecFBox {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        // 标题
        .ecFTitle {
            width: 50%;
            height: 170px;
            margin: 0 auto;
            .ecFBig {
                width: 100%;
                height: 50px;
                line-height: 50px;
                text-align: center;
                font-weight: 900;
                font-size: 36px;
                margin-bottom: 20px;
            }
            .ecFSmall {
                width: 100%;
                text-align: center;
                line-height: 25px;
            }
        }
        // 图片
        .ecFImgs {
            width: 100%;
            height: 400px;
            margin-top: 20px;
            text-align: center;
            .ecfImg {
            }
        }
    }
}
</style>
