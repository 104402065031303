<template>
    <!-- 展会营销 -->
    <div class="exhibition">
        <!-- 内容一 -->
        <ExContentOne></ExContentOne>
        <!-- 内容二 -->
        <ExContentTwo></ExContentTwo>
        <!-- 内容三 -->
        <ExContentThree></ExContentThree>
        <!-- 内容四 -->
        <ExContentFour></ExContentFour>
        <!-- 内容五 -->
        <ExContentFive></ExContentFive>
        <!-- 内容六 -->
        <ExContentSix></ExContentSix>
        <!-- 内容七 -->
        <ExContentSeven></ExContentSeven>
    </div>
</template>

<script>
import ExContentOne from "./ExContent/ExContentOne.vue";
import ExContentTwo from "./ExContent/ExContentTwo.vue";
import ExContentThree from "./ExContent/ExContentThree.vue";
import ExContentFour from "./ExContent/ExContentFour.vue";
import ExContentFive from "./ExContent/ExContentFive.vue";
import ExContentSix from "./ExContent/ExContentSix.vue";
import ExContentSeven from "./ExContent/ExContentSeven.vue";

export default {
    name: "Exhibition",
    components: {
        ExContentOne,
        ExContentTwo,
        ExContentThree,
        ExContentFour,
        ExContentFive,
        ExContentSix,
        ExContentSeven,
    },
};
</script>

<style lang="less" scoped>
.exhibition {
    min-width: 1423px;
    margin: 0 auto;
    text-align: left;
}
</style>
