<template>
    <div class="exContentTwo">
        <img src="http://iv.okvu.cn/vugw/img/bg1 (1).jpg" alt="" class="ectBg" />
        <div class="ectBox">
            <!-- 标题 -->
            <div class="ectTitle wow animate__animated animate__slideInUp">
                <p class="ectBig">展会威有客</p>
                <p class="ectSmall">是一套辅助中小企业进行展会营销的互联网解决方案</p>
                <p class="ectSmall">
                    采用H5动画、小程序等技术手段，利用微信平台的优势，通过朋友圈转发、现场扫码关注、游戏互动等形式，
                    在展会现场搭建出具有扩客引流、互动锁客、客情管理功能的客流营销体系，帮助参展会在展会过程中突破展位局限，获得
                    潜在客户信息，最大程度的扩大营销效果。
                </p>
            </div>
            <!-- 图文列表 -->
            <div class="ectList">
                <ul>
                    <li class="ectLi1 wow animate__animated animate__slideInDown">
                        <p class="ectText">展前邀请函</p>
                        <img src="https://iv.okvu.cn/vugw/img/pic-1-1.png" alt="" class="ectImg" />
                    </li>
                    <li class="ectLi2 wow animate__animated animate__slideInUp">
                        <img src="https://iv.okvu.cn/vugw/img/pic-1-2.png" alt="" class="ectImg" />
                        <p class="ectText">电子宣传画册</p>
                    </li>
                    <li class="ectLi1 wow animate__animated animate__slideInDown">
                        <p class="ectText">现场互动抽奖</p>
                        <img src="https://iv.okvu.cn/vugw/img/pic-1-3.png" alt="" class="ectImg" />
                    </li>
                    <li class="ectLi2 wow animate__animated animate__slideInUp">
                        <img src="https://iv.okvu.cn/vugw/img/pic-1-4.png" alt="" class="ectImg" />
                        <p class="ectText">展前营销小程序</p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ExContentTwo",
    data() {
        return {};
    },
    mounted() {
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: "ease-in-sine",
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: "wow",
            animateClass: "animate__animated",
            offset: 0,
            mobile: true,
            live: true,
        });
    },
};
</script>

<style lang="less" scoped>
.exContentTwo {
    width: 100%;
    height: 700px;
    margin-top: 50px;
    position: relative;
    .ectBg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        z-index: -1;
    }
    .ectBox {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        // 标题
        .ectTitle {
            width: 60%;
            height: 170px;
            margin: 0 auto;
            .ectBig {
                width: 100%;
                height: 50px;
                line-height: 50px;
                text-align: center;
                font-size: 36px;
                font-weight: 900;
                margin-bottom: 20px;
            }
            .ectSmall {
                width: 100%;
                height: 25px;
                line-height: 25px;
                text-align: center;
            }
        }
        // 图文列表
        .ectList {
            width: 70%;
            height: 450px;
            margin: 0 auto;
            margin-top: 50px;
            ul {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-evenly;
                .ectLi1,
                .ectLi2 {
                    width: 24%;
                    height: 80%;
                    text-align: center;
                    color: white;
                    .ectText {
                        width: 100%;
                        height: 50px;
                        line-height: 50px;
                        font-size: 18px;
                    }
                    .ectImg {
                    }
                }
                .ectLi2 {
                    margin-top: 10%;
                }
                // .ectLi:hover {
                //   transform: translateY(-20px);
                //   transition: 0.5s;
                // }
            }
        }
    }
}
</style>
